<template>
  <div id="graph-info-container" class="full-height" style="overflow-y: auto;">
    <div class="q-ma-lg">
      <q-card class="q-pa-sm full-height">
        <q-card-section>
          <h5 style="margin: 5px 0;">
            Graph Info
          </h5>
        </q-card-section>
        <q-card-section>
          <GraphInfoItem
            title="Graph Name: "
            :content="autoGeneratedCurrentGraphObjectTitle"
          ></GraphInfoItem>
          <GraphInfoItem
            title="Is Published? "
            :content="autoGeneratedCurrentGraphObjectIsPublished ? '✅' : '❌'"
          ></GraphInfoItem>
          <GraphInfoItem
            title="Abstract: "
            :html="autoGeneratedCurrentGraphObjectAbstractHtml"
          >
          </GraphInfoItem>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    components: {
      GraphInfoItem: () => import('@/components/tutorial/GraphInfoItem.vue'),
    },
    computed: {
      ...mapGetters('graphs', [
        'autoGeneratedCurrentGraphObjectTitle',
        'autoGeneratedCurrentGraphObjectIsPublished',
        'autoGeneratedCurrentGraphObjectAbstractHtml',
      ]),
    },
  };
</script>

<style lang="sass">
  #graph-info-container
    padding-bottom: 24px
</style>
